@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary-lighter: 136, 200, 240; // rgb(136, 200, 240)
  --color-primary-light: 50, 189, 245; // rgb(50, 189, 245)
  --color-primary-200: 250, 253, 255; //rgb(250, 253, 255)
  --color-primary-300: 219, 237, 251; // rgb(219, 237, 251)
  --color-primary-400: 72, 183, 255; // rgb(72,183,255)
  --color-primary-500: 33, 169, 255; // rgb(33,169,255)
  --color-primary-600: 29, 155, 255; // rgb(29,155,255)
  --color-primary-700: 22, 137, 254; // rgb(22,137,254)
  --color-primary-default: 21, 153, 249; // rgb(21, 153, 249)
  --color-primary-dark: 0, 138, 239; // rgb(0, 138, 239)

  --color-secondary-lighter: 221, 155, 232; // rgb(221, 155, 232)
  --color-secondary-light: 186, 104, 200; // rgb(186, 104, 200)
  --color-secondary-default: 156, 39, 176; // rgb(156, 39, 176)
  --color-secondary-dark: 123, 31, 162; //:rgb( 123, 31, 162)

  --color-error-lighter: 242, 138, 136; //rgb(242, 138, 136)
  --color-error-light: 239, 83, 80; //rgb(239, 83, 80)
  --color-error-default: 211, 47, 47; //rgb(211, 47, 47)
  --color-error-dark: 198, 40, 40; //rgb(198, 40, 40)

  --color-warning-lighter: 245, 192, 115; //rgb(245, 192, 115)
  --color-warning-light: 255, 152, 0; //rgb(255, 152, 0)
  --color-warning-default: 237, 108, 2; //rgb(237, 108, 2)
  --color-warning-dark: 230, 101, 16; //rgb(230, 101, 16)

  --color-info-lighter: 107, 200, 242; //rgb(107, 200, 242)
  --color-info-light: 3, 169, 244; //rgb(3, 169, 244)
  --color-info-default: 2, 136, 209; //rgb(2, 136, 209)
  --color-info-dark: 1, 87, 157; //rgb(1, 87, 157)

  --color-success-lighter: 125, 219, 129; //rgb(125, 219, 129)
  --color-success-light: 76, 175, 80; //rgb(76, 175, 80)
  --color-success-default: 46, 125, 50; //rgb(46, 125, 50)
  --color-success-dark: 27, 83, 32; //rgb(27, 83, 32)

  --color-dark-lighter: 255, 255, 255; // rgb(255 255 255)
  --color-dark-light: 240, 240, 240; // rgb(240 240 240)
  --color-dark-200: 205, 205, 205; // rgb(205 205 205)
  --color-dark-300: 150, 150, 150; // rgb(150 150 150)
  --color-dark-400: 90, 90, 90; // rgb(100 100 100)
  --color-dark-500: 77, 77, 77; // rgb(77 77 77)
  --color-dark-600: 60, 60, 60; // rgb(60 60 60)
  --color-dark-700: 30, 30, 30; // rgb(30 30 30)
  --color-dark-default: 0, 23, 30; // rgb(17 17 17)
  --color-dark-darker: 10, 10, 10; // rgb(10 10 10)

  --font-display: Futura, Trebuchet MS, Arial, sans-serif;
  --font-body: 'Inter', sans-serif;

  --font-weight-normal: 400;
  --font-weight-display: 600;
  --font-weight-btn: 600;

  --rounded-btn: 9999px;
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;

  src: url('./public/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf');
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(75 85 99) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  // width: 16px;
  @apply w-1.5 h-1.5;
}

*::-webkit-scrollbar-track {
  @apply bg-transparent;
}

*::-webkit-scrollbar-thumb {
  @apply bg-dark-400 dark:bg-dark-600;
}

.StripeElement {
  @apply p-2.5 border bg-transparent border-gray-300 dark:border-gray-600 dark:placeholder-dark-400 
  transition-colors duration-300 ease-in-out rounded-md;
}

.StripeElement--invalid {
  @apply border-error dark:border-error hover:border-error dark:hover:border-error-light focus:border-error dark:focus:border-error-light;
}
